import { getApiRoutes } from '@adac/core-model';
import { request } from '@adac/core-view';

export interface Driver {
  id: number;
  firstName: string;
  familyName: string;
  photo: string | null;
  user: null | {
    roles: [{ name: string }];
  };
}

export const getDrivers = async (token: string) => {
  const url = getApiRoutes().driverAdmin.list;
  const drivers = await request<Driver[]>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }) as Driver[];
  // TODO: add query params for pagination, like in getCase
  return { data: drivers, total: undefined };
};
