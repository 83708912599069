import { CompanyBoss, getApiRoutes } from '@adac/core-model';
import { request } from '@adac/core-view';

export const getMe = async (token: string) => {
  const url = getApiRoutes().auth.users.getMe;
  const user = await request<CompanyBoss>(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (user) return user;
  throw new Error('No user returned');
};
