import {
  Button, ButtonTitle, ErrorMessage, NotificationSettingsForm, SubTitle, useLocation, View, useMyNotificationSettings,
  NotificationSettingsFormSchemaPartner,
  saveNotificationSettings,
} from '@adac/core-view';
import {
  Form, Formik, FormikHelpers as FormikActions, FormikProps,
} from 'formik';

import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  NotificationSettingsFormValues, __,
  defaultNotificationSettingValues,
  CompanyTypes,
  CompanyBoss,
  getApiRoutes,
  getAllNotificationSettingValues,
  activeNotificationSettings,
} from '@adac/core-model';
import StoresContext from '../../stores';
import { LinkButton } from '../overlays/Profile';


const Page = styled(View)`
  background-color: #ebeaea;
  padding: 30px 10px;
  height: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
`;


const ConfirmButtons = styled(View)`
  margin-top: 10px;
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;


const NotificationSettings = () => {
  const stores = useContext(StoresContext);
  const settings = useMyNotificationSettings(stores.auth.token as string);
  const location = useLocation();
  const companyType = (stores.auth?.userData as CompanyBoss)?.companyType || CompanyTypes.EXT;

  const onSubmit = async (values: NotificationSettingsFormValues) => {
    try {
      await saveNotificationSettings(stores.auth.token as string, { settings: values }, getApiRoutes().partner.notificationSettings);
      location.goBack();
    } catch (error) {
      stores.ui.setOverlay(<ErrorMessage>{__((error as any)?.toLocalizedString() ?? `${error}`)}</ErrorMessage>);
    }
  };

  return (
    <Page>
      <SubTitle>{__('Notification settings')}</SubTitle>
      <Formik
        initialValues={settings || defaultNotificationSettingValues[companyType]}
        validationSchema={NotificationSettingsFormSchemaPartner}
        validateOnMount
        enableReinitialize
        onSubmit={async (values: NotificationSettingsFormValues, actions: FormikActions<NotificationSettingsFormValues>) => {
          await onSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }: FormikProps<NotificationSettingsFormValues>) => (
          <Form>
            <NotificationSettingsForm
              allSettingValues={getAllNotificationSettingValues(activeNotificationSettings)}
              activeSettingValues={activeNotificationSettings}
            />
            <ConfirmButtons>
              <Button
                disabled={isSubmitting || !isValid}
                type="submit"
                ctaBorder
                isLoading={isSubmitting}
                title={__('Save')}
              />

              <LinkButton info link="/">
                <ButtonTitle>{__('Cancel')}</ButtonTitle>
              </LinkButton>
            </ConfirmButtons>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default NotificationSettings;
