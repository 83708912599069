import {
  Company,
  Driver, DriverFormValues, getApiRoutes,
} from '@adac/core-model';
import { request } from '@adac/core-view';

export const createDriver = async (data: Driver, token: string) => {
  const url = getApiRoutes().driverAdmin.create;
  const res = await request<object, Driver>(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return res;
};

export const updateDriver = async (driverId: string | number, data: DriverFormValues, token: string) => {
  const url = getApiRoutes().driverAdmin.get(driverId);
  const res = await request(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return res;
};

export const deleteDriver = async (driverId: string | number, token: string) => {
  const url = getApiRoutes().driverAdmin.get(driverId);
  try {
    const res = await request(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getMyCompany = async (authToken: string) => {
  const url = getApiRoutes().partner.getMyCompany;
  return request<Company>(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken || ''}`,
    },
  });
};
